import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

import langEn from './lang.en.json';
import langKo from './lang.ko.json';
import langJa from './lang.ja.json';
import langZhcn from './lang.zh_cn.json';
import langZhtw from './lang.zh_tw.json';
import langVi from './lang.vi.json';

const resources: Resource = {
  en: { translation: langEn },
  ko: { translation: langKo },
  ja: { translation: langJa },
  zh_cn: { translation: langZhcn },
  zh_tw: { translation: langZhtw },
  vi: { translation: langVi },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('initLangCode') || 'ko', // 초기 설정 언어
  fallbackLng: 'en', // 한국어 불러오는 것이 실패했을 경우 영문
  debug: true,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
