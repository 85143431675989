import { ICON_PATH } from 'src/utils/constants';
import styled from 'styled-components';

/**
 * @name 아이콘 스타일 컴포넌트
 */
export const StyledIcon = styled.span`
  background: transparent
    url('https://data.ac-illust.com/data/thumbnails/3a/3a6979f7f1cd310149a3f311fee442aa_t.jpeg')
    no-repeat 50% 50%;
  background-size: 100% 100%;
  display: block;
  cursor: pointer;

  &.ico_bell {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_bell_black.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_bell_red {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_bell_red.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_hamburger {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_menu.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_point {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_point.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_point_241212 {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_point_241212.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_barcode {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_barcode.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_smartreceipt {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_smartreceipt.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_leaflet {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_leaflet.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_recomprod {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_recom.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_mybenefit {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_benefit.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_event {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_event.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_close {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_close.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_clock {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_clock.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_date {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_date.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_dropdown {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_dropdown.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_dropdown_bold {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_dropdown_bold.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_dropdown_bold2 {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/ico_dropdown_bold2.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_dropdown_pink {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_dropdown_pink.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_search {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_search.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.ico_back {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_back.png') no-repeat 0 0;
    background-size: 100% auto;
    margin-right: 10px;
  }
  &.ico_home {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_home.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.ico_carbon_neutral {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_carbon_neutral.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.ico_check_off {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_check_off.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.ico_question {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_question.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_arrow_black {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_arrow_black.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_arrow_black_round {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_arrow_black_round.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_arrow_white {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_arrow_white.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_arrow_white_round {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_arrow_white_round.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_arrow_red {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_arrow_red.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_status_on {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_status_on.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_status_off {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_status_off.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_location {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_location.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_tell {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_tell.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_heart {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_heart.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_heart_on {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_heart_on.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_heart_off {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_heart_off.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_prohibition {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_prohibition.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_small_barcode {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_small_barcode.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_check_gray {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_check_gray.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_noti_2 {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_noti_2.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_noti_3 {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_noti_3.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_store {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_store.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_info {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_info.png') no-repeat 0 0;
    background-size: 100% auto;
  }

  &.icon_auth_employee {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_authEmployee.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_no_data {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_no_data.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_dot {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_dot.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_filter {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_filter.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_clear {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_clear.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_clear_black {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_clear_black.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_list {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_list.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_grid {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_grid.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_logo_receipt {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_logo_receipt.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_ruled_line {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_ruled_line.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_login_error {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_login_error.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_no_list {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_no_list.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_no_prod {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_no_prod.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_leaflet2 {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_leaflet2.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_calender {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_calender.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_calender_black {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_calender_black.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_reset {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_btn_reset.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_big_arrow_blue {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/arrow_blue.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_big_arrow_black {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/arrow_black.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_scan_info01 {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_scan_info01.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_scan_info02 {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_scan_info02.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_scan_info03 {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_scan_info03.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_done {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_done.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_download_black {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_download_black.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_download_white {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/ico_download_white.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_download_gray {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_download_gray.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_download_dark_gray {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_download_dark_gray.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_line_black {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_line_black.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_appcard_close {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_appcard_close.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_play {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_play.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_pause {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_pause.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_heart_on {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_heart_on.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_heart_off {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_heart_off.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_scroll_top {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_scroll_top.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_lang {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_lang.png') no-repeat 0 0;
    background-size: 100% auto;
  }
  &.icon_event_coupon {
    width: 100%;
    height: 100%;
    background: url('${ICON_PATH}/icon_event_coupon.png') no-repeat 0 0;
    background-size: 100% auto;
  }
`;
