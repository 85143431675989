import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { actionNoneMainMenu } from 'src/redux/actions/mainActions';
import { StyledSideMenuList } from '../styles';
import { bridgeOpenBrowser, bridgeOpenLeaflet } from 'src/utils/bridge';
import useSelectorEqual from 'src/hooks/useSelectorEqual';
import { RootState } from 'src/store/reducers';
import { SUPER_CHECK } from 'src/utils/constants';
import { gaEvent } from 'src/utils/ga';

function SideMenuHelpSection(): React.ReactElement {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [mainStoreState, userInfoState] = useSelectorEqual(
    (state: RootState) => [state.user.mainStoreState, state.user.userInfoState],
  );

  const handleClickNotice = () => {
    setGaEvent('공지사항');
    navigate('/alarm/notice');
    closePopup();
  };

  const handleClickFaq = () => {
    setGaEvent('자주묻는질문');
    navigate('/faq');
    closePopup();
  };

  const handleClickQna = () => {
    setGaEvent('1:1문의');
    navigate('/qna/register');
    closePopup();
  };

  const handleClickQnaList = () => {
    setGaEvent('문의내역확인');
    navigate('/qna');
    closePopup();
  };

  const handleClickEmployee = () => {
    setGaEvent('임직원인증');
    navigate('/authemployee');
    closePopup();
  };

  const handleClickChatbot = () => {
    setGaEvent('챗봇');
    bridgeOpenLeaflet(
      'http://vscode-1408018676.us-east-1.elb.amazonaws.com:8000/',
    );
  };

  const closePopup = () => {
    dispatch(actionNoneMainMenu());
  };

  const hanldeClickMartReduce = () => {
    setGaEvent('롯데마트소개');
    bridgeOpenBrowser('https://company.lottemart.com/');
  };
  const hanldeClickSuperReduce = () => {
    setGaEvent('롯데슈퍼소개');
    bridgeOpenBrowser(
      'https://company.lottesuper.co.kr/handler/companyinfo/CompanyInfo-Start',
    );
  };
  const hanldeClickMobileDowa = () => {
    setGaEvent('모바일도와센터');
    bridgeOpenBrowser('https://company.lottemart.com/mobiledowa');
  };
  const handleClickMyCarRegister = () => {
    setGaEvent('내 차량 번호 등록');
    navigate('/mycar/register');
    closePopup();
  };

  const setGaEvent = title => {
    gaEvent(
      `${title} 버튼`,
      mainStoreState.main_str_type_nm,
      '',
      '전체메뉴 팝업',
    );
  };

  return (
    <StyledSideMenuList>
      <ul>
        <span>도움</span>
        <li onClick={handleClickNotice}>공지사항</li>
        <li onClick={hanldeClickMartReduce}>롯데마트 소개</li>
        <li onClick={hanldeClickSuperReduce}>롯데슈퍼 소개</li>
        <li onClick={handleClickFaq}>자주묻는 질문</li>
        <li onClick={handleClickQna}>1:1건의</li>
        {!SUPER_CHECK(mainStoreState.str_cd) && (
          <li onClick={handleClickQnaList}>건의내역 확인</li>
        )}
        <li onClick={hanldeClickMobileDowa}>모바일 도와센터</li>
        <li onClick={handleClickEmployee}>임직원 인증</li>
        <li onClick={handleClickMyCarRegister}>내 차량 번호 등록</li>
        {userInfoState.emp_yn === 'Y' && (
          <li onClick={handleClickChatbot}>
            <span>AI 챗봇 테스트</span>
          </li>
        )}
      </ul>
      <div className="base_line" />
    </StyledSideMenuList>
  );
}

export default SideMenuHelpSection;
